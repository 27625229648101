.cursor-dot,
.cursor-dot-outline{
  display: none;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform .3s ease-in-out;
  z-index: 999;
}

.cursor-dot{
  width: 30px;
  height: 30px;
  background-color: var(--primary-blue-9);
}

.cursor-dot-outline{
  width: 45px;
  height: 45px;
  border: 1px solid var(--primary-blue-9);
}

@media (min-width: 992px) {
  .cursor-dot,
  .cursor-dot-outline{
    display: block;
  }
}