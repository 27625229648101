/* projects */
.project-hero-image{
  width: 100%;
  height: 60vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin-bottom: 1em;
}
.project-list {
  display: block;
  margin-bottom: 2.5em;
  height: 0;
  overflow: hidden;
  transform: height .3s ease-in-out;
}
.project-list.show{
  height: 500px;
}

.project-list__title{
  padding: 20px 0 0;
}
.project-list__image {
  width: 100%;
  height: 350px;
  position: relative;
}
.project-list__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 992px) {
  .project-list.show{
    height: 575px;
  }
  .project-list__image {
    height: 500px;
  }
}