.side-service-container{
  position: fixed;
  top: 0;
  right: 0;
  width: 65px;
  height: 100vh;
  white-space: nowrap;
  overflow: hidden;
  display: none;
  visibility: hidden;
}

.side-service-text{
  text-shadow: 
    1px 1px 0px var(--main-text-color),
    -1px 1px 0px var(--main-text-color),
    1px -1px 0px var(--main-text-color),
    -1px -1px 0px var(--main-text-color);
  color: var(--main-bg-color);
  right: 0;
  top: 0;
  margin-bottom: 0;
  display: block;
  font-size: 1.8em;
  transform: rotateZ(90deg);
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .side-service-container{
    display: block;
    visibility: visible;
  }
}