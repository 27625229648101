@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

:root {
  --main-bg-color: #141414;
  --main-text-color: #F6F8FF;
  --gray-700: #adb5bd;
  --gray-900: #212529;
  --primary-blue: #4361ee;
  --primary-blue-9: rgba(67, 98, 238, 0.9);
  --primary-rose: #FF5964;
  --display-font-size: 1.8em;
  --light-color: #F6F8FF;
}

@media (min-width: 992px){
  :root {
    --display-font-size: 3em;
  }
}

::selection{
  background-color: var(--primary-blue);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* cursor: none !important; */
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

a {
  text-decoration: underline;
  color: #f8f8f8;
  transition: all .3s ease-in-out;
}

a:hover {
  opacity: .75;
}

a i {
  margin-left: 10px;
  transition: all .3s ease;
}

a:hover i {
  transform: translateX(10px);
}

body{
  font-family: 'Inter', sans-serif;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-size: 25px;
}
@media (min-width: 992px) {
  body{
    font-size: 30px;
  }
}

.header{
  padding: 30px 0;
}

.header-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-nav {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 25px;
}

@media (min-width: 992px){
  .main-nav {
    display: flex;
  }
}

.main-nav i {
  font-size: 1.8em;
}

.mobile-nav {
  display: flex;
}

.mobile-nav__button {
  width: 65px;
  height: 65px;
  font-size: 1.35em;
  background: transparent;
  border: 0;
  color: var(--main-text-color);
  z-index: 99;
}
.mobile-nav__content{
  position: fixed;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  font-size: 1.8em;
  perspective-origin: center right;
  perspective: 101vw;
  transition: all .3s ease-in-out;
  z-index: 98;
}
.mobile-nav__content.active {
  width: 100%;
}
.mobile-nav__content.active > .mobile-nav-items {
  transform: rotateY(0deg);
}
.mobile-nav-items{
  display: flex;
  position: fixed;
  background: var(--primary-blue-9);
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 150px 25px 25px;
  gap: 0.5em;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(-270deg);
  transform-origin: center right;
  transition: all .3s ease-in-out;
  animation-delay: .4s;
}

.mobile-nav-items > * {
  opacity: 0;
  transition: opacity .3s ease;
  animation-delay: .3s;
}

.mobile-nav__content.active >  .mobile-nav-items > * {
  opacity: 1;
}

@media (min-width: 992px){
  .mobile-nav, .mobile-nav-items {
    display: none;
  }
}


.brand{
  width: 180px;
}

.hero {
  margin-top: 35vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
  "HeroText HeroText HeroText HeroText"
  "HeroParagraph HeroParagraph HeroParagraph HeroParagraph"
  "HeroActions HeroActions HeroActions HeroActions"
  ". . . .";
}

.hero-raccoon{
  width: 100%;
  position: absolute;
  right: 0px;
  top: 30%;
  image-rendering: pixelated;
  filter: grayscale(1);
  mix-blend-mode: soft-light;
  z-index: -1;
}

@media (min-width: 992px) {
  .hero {
    margin-top: 30vh;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 0.5fr;
    grid-template-areas:
    "HeroText HeroText HeroText HeroText"
    "HeroParagraph HeroParagraph HeroActions HeroActions"
    ". . . .";
  }
  .hero-raccoon{
    width: 55%;
    right: 5%;
    top: 13%;
  }
}

.HeroText { 
  grid-area: HeroText;
  margin-bottom: 50px;
}
.HeroParagraph { 
  padding: 30px 10px;
  grid-area: HeroParagraph;
}
.HeroActions { 
  padding: 30px 10px;
  gap: 20px;
  grid-area: HeroActions;
  display: flex;
  flex-direction: column;
}

.hero h1{
  font-size: 1.8em;
}
@media (min-width: 992px) {
  .hero h1{
    font-size: 2.4em;
  }
}

.hero h1 span{
  text-decoration: underline;
}

h2{
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.4em;
}

@media (min-width: 992px) {
  .hero h1 {
      font-size: 3.2em;
  }

  h2 {
      font-size: 2em;
  }

  h3 {
      font-size: 1.8em;
  }

  h3 {
      font-size: 1.8em;
  }
}

.container {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .container {
      width: 900px;
  }
}

@media (min-width: 1200px) {
  .container {
      width: 1140px;
  }
}
/* Featured works */
.featured-works{
  margin-top: 7em;
}

.work-item{
  margin: 7.2em 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 30px;
  grid-template-areas:
  "WorkImage WorkImage"
  "WorkText WorkText";
}

.work-item:nth-last-of-type(even){
  grid-template-areas:
  "WorkImage WorkImage"
  "WorkText WorkText";
}
@media (min-width: 992px) {
  .work-item{
    gap: 0px 30px;
    grid-template-areas:
    "WorkImage WorkText"
    "WorkImage WorkText";
  }
  .work-item:nth-last-of-type(even){
    grid-template-areas:
    "WorkText WorkImage"
    "WorkText WorkImage";
  }
}

.WorkImage{
  grid-area: WorkImage;
  position: relative;
}

.WorkImage img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.WorkText{
  grid-area: WorkText;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WorkText > a {
  margin: 1.5em 0;
}
.WorkText__desc{
  margin-top: 1.5em;
}

.work-intro{
  margin: 3em 0;
  width: 100%;
}

.work-intro h1{
  margin-bottom: 0.2em;
}

.work-intro > .badge {
  margin-bottom: 1em;
}

@media (min-width: 992px) {
  .work-intro{
    width: 70%;
  }  
}

/* Meet Us */
.meet-us h2{
  margin-top: 1.5em;
  margin-bottom: 3em;
}
.photos{
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: 
  "BrandImage team-designer ."
  "BrandImage team-designer team-developer"
  "BrandImage . team-developer";
  gap: 40px;
  position: relative;
}

.photos-raccoon{
  height: 70%;
  position: absolute;
  left: 0;
  top: 20%;
  image-rendering: pixelated;
  filter: grayscale(1);
  mix-blend-mode: soft-light;
  z-index: -1;
}

.BrandImage{
  grid-area: BrandImage;
}

.team-designer{
  grid-area: team-designer;
  display: flex;
  flex-direction: column;
}

.team-developer{
  grid-area: team-developer;
  display: flex;
  flex-direction: column;
}

.team-desc{
  margin-top: 1em;
}

.team-photo img{
  width: 100%;
  object-fit: cover;
}

.team-designer .team-desc h4,
.team-developer .team-desc h4 {
  margin-bottom: 1em;
}

/* Comments */
.comments{
  margin-top: 120px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 90px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
}

.comment .author{
  font-weight: bold;
}

/* About */
.about-hero{
  height: 75vh;
  display: flex;
  align-items: center;
}

.about-hero h1 {
  font-size: var(--display-font-size);
  margin-bottom: 40px;
}

.about-hero p {
  font-size: 1em;
  width: 100%;
}
@media (min-width: 992px) {
  .about-hero p {
    font-size: 1.4em;
    width: 85%;
  }
}

.about-section {
  padding: 2em 0;
  margin-top: 2em;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row: 1;
  gap: 1em;
  align-items: center;
}

.about-section h2{
  margin-bottom: 1em;
}

.about-sect-raccoon{
  width:100%;
  image-rendering: pixelated;
  filter: grayscale(1);
  mix-blend-mode: soft-light;
  z-index: -1;
  grid-row: 2;
  margin-top: -100%;
}

@media (min-width: 992px) {
  .about-section {
    grid-template-columns: 1fr 1.3fr;
    gap: 2.5em;
  }  
  .about-sect-raccoon{
    width: 100%;
    grid-row: 1;
    margin-top: 0;
  }
}

/* Contact */
.contact{
  border-top: 2px solid white;
  padding-top: 2.5em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 5em 0;
  position: relative;
}

.contact h2{
  margin-bottom: 0.4em;
}

.contact-raccoon{
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 30%;
  image-rendering: pixelated;
  filter: grayscale(1);
  mix-blend-mode: soft-light;
  z-index: -1;
}
@media (min-width: 992px) {
  .contact{
      grid-template-columns: 2fr 1.5fr;
      grid-template-rows: 2fr 1.5fr;
  }
  .contact-raccoon{
    width: 50%;
    right: 0;
    top: 0;
  }
}

/* Form styles */
label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
}

.ContactBrand {
  text-align: right;
}

#contact-form{
  width: 100%;
  margin: 0 auto;
}

.contact-title{
  font-size: var(--display-font-size);
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
@media (min-width: 992px) {
  #contact-form{
    width: 60%;
  }
}

.form-control{
  border: 2px solid white;
  border-radius: 0;
  padding: 20px;
  font-size: 18px;
  height: 60px;
  width: 100%;
  display: inline-block;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.form-control:focus-visible {
  outline: none;
  border: 2px solid #4361ee;
}

.form-group{
  margin-bottom: 1em;
}

.form-control textarea{
  font-family: 'Inter', sans-serif;
}

.badge{
  display: inline-block;
  background-color: var(--gray-900);
  color: var(--gray-700);
  border-radius: 50px;
  padding: 10px 25px;
  font-size: 0.8em;
  margin-top: .5em;
  margin-bottom: .5em;
}

/* buttons */
.btn-group{
  display: flex;
  gap: 15px;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 100%;
  overflow-y: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.btn-send{
  background-color: white;
  border: none;
  color: #212121;
  padding: 20px 25px;
  font-weight: 400;
  font-size: 0.7em;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.btn-send:hover{
  background-color: var(--primary-blue);
  color: var(--main-text-color);
}

.btn-filter {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 50px;
  color: var(--main-text-color);
  padding: 20px 25px;
  font-weight: 400;
  font-size: 0.7em;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.btn-filter.active {
  background-color: var(--primary-blue);
  border: 2px solid var(--primary-blue);
}

/* Alerts */
#success-alert{
  background-color: var(--primary-blue);
  padding: 1.3em 2em;
  width: 40%;
  font-size: 0.75em;
  top: 2em;
  right: 0;
  opacity: 0;
  position: fixed;
  transform: rotateX(90deg);
  transition: all .3s ease-in-out;
}

#success-alert.show{
  transform: rotateX(0);
  opacity: 100;
  right: 2em;
}

/* helper */
.text-center{
  text-align: center;
}

/* Dropdown */
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-box {
  background: #f5f5f5;
  color: #1b1b1b;
  position: absolute;
  top: 120%;
  right: 0;
  display: flex;
  flex-direction: column;
  transform-origin: top;
  z-index: 99;
  transform: rotateX(90deg);
  visibility: hidden;
  transition: transform .25s ease-in-out, visibility .3s ease;
}

.dropdown-box:after {
  content: '';
  z-index: -1;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: 10px;
  background: #f5f5f5;
  transform: rotate(45deg);
}

.dropdown:hover > .dropdown-box, .dropdown-box:hover, .dropdown:focus > .dropdown-box, .dropdown-box:hover {
  transform: rotateX(0deg);
  visibility: visible;
}

.dropdown-item{
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  transition: all .3s ease-in-out;
}

.dropdown-item.active {
  text-decoration: underline;
}

.dropdown-item:hover{
  background-color: #dadada;
}

/* Services section */
.services-section{
  margin: 3em 0;
  text-align: center;
}

/* Footer */
.footer-brand{
  height: 80px;
}

footer {
  margin: 2em 0 1.5em;
  font-size: 20px;
  color: #6e6e6e;
}